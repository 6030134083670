import { ThemeProvider, createTheme } from '@mui/material';
import styled from 'styled-components';
import { mainBlue, mainSkyBlue } from './global/css';
import MainPage from './pages/MainPage';
import './reset.css';
import React, { useEffect } from 'react';

function App() {
  if (process.env.NODE_ENV === 'production') {
    console = window.console || {};
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.error = function () {};
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: mainBlue,
        contrastText: mainSkyBlue,
      },
    },
  });


  useEffect(() => {
    const id = prompt("ID를 입력해주세요.:");
    const pw = prompt("비밀번호를 입력해주세요:");
    // You can handle the ID and password here, e.g., send them to a server
    if(id=="cpadmin" && pw=="19435768!@"){
      alert("관리자 로그인 성공");
    }else{
      window.location.replace("https://chunpoong.co.kr/");
    }
  }, []);

  return (
    <AppWrapper>
      <ThemeProvider theme={theme}>
        <MainPage />
      </ThemeProvider>
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div``;
